import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import copy from 'copy-text-to-clipboard'
import { FiCopy } from 'react-icons/fi'

import Layout from "../components/layout"
import SEO from "../components/seo"

const VenmoPage = () => {
  return (
    <Layout>
      <SEO title="Chef Tu" />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20
      }}>
          <div><StaticImage alt="venmo" src="../images/venmo.png" /></div>
      </div>
    </Layout>
  )
}

export default VenmoPage
